import React from "react";
import { graphql } from "gatsby";
import EditorialContentComponent from "../../components/editorial-content";
import { useI18next } from "gatsby-plugin-react-i18next";

export const query = graphql`
  fragment StrapiNewsletterEditorialContentFragment on StrapiNewsletterEditorialContent {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query EditorialContent($Slug: String!, $language: String!) {
    editorialContent: strapiEditorialContent(Slug: { eq: $Slug }) {
      Slug
      AlternateSlug
      Title
      Description
      therapeutical_area {
        Name
        Slug
        id
      }
      editorial_content_type {
        Name
        Slug
      }
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentDownloads {
        localFile {
          publicURL
        }
      }
    }
    strapiNewsletterEditorialContentEn: strapiNewsletterEditorialContent(
      locale: { eq: "en" }
    ) {
      ...StrapiNewsletterEditorialContentFragment
    }
    strapiNewsletterEditorialContentEs: strapiNewsletterEditorialContent(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterEditorialContentFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const EditorialContent = ({ data }) => {
  const { language, t } = useI18next();
  if (!data) return null;
  const newsletter =
    language === "en"
      ? data.strapiNewsletterEditorialContentEn
      : data.strapiNewsletterEditorialContentEs;
  return (
    <EditorialContentComponent
      type="medical-informations"
      editorialContent={data.editorialContent}
      parentTitle={t(`nav.medicalInformation`)}
      parentLink="/medical-informations"
      newsletter={newsletter}
    />
  );
};

export default EditorialContent;
