import React, { useEffect } from "react";
import Layout from "./layout";
import Brick from "./bricks/bricks";
import { Box, Container, Typography } from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import BrickImageText from "./bricks/image-text";
import Breadcrumb from "./breadcrumbs/breadcrumb";
import { formatAlternatePath } from "../utils/alternate-lang";
import { isFullLength } from "../utils";
import BrickNewsletter from "./bricks/newsletter";
import { toGtmLabel } from "../utils/index";
import { gtmPushUserInfo } from "../services/auth";

const EditorialContentComponent = (props) => {
  const { type, editorialContent, parentTitle, parentLink, newsletter } = props;

  const { language } = useI18next();
  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: toGtmLabel(type),
      page_ta: editorialContent?.therapeutical_area.Name,
      page_type:
        "Disease Awareness" === editorialContent?.editorial_content_type.Name
          ? "Therapeutic Area"
          : editorialContent?.editorial_content_type.Name,
    });
    gtmPushUserInfo();
  }, [language, type, editorialContent]);

  if (!editorialContent) return null;

  const alternateLang = language === "en" ? "es" : "en";
  const seo = {
    metaTitle: editorialContent.Title,
    metaDescription: (editorialContent.Description || "").split("\n").join(" "),
    linkAlternateHref: editorialContent.AlternateSlug
      ? `${formatAlternatePath(
          editorialContent.AlternateSlug,
          props.type,
          alternateLang
        )}`
      : null,
    linkHrefLang: editorialContent.AlternateSlug ? alternateLang : null,
  };

  const {
    headerImage,
    pageContent,
    pageContentFullWidthImages,
    pageContentConstrainedImages,
    pageContentSeeMoreThumbs,
    pageContentDownloads,
  } = editorialContent;

  const getImageContent = (i) => {
    return pageContentFullWidthImages[i]?.localFile !== null
      ? pageContentFullWidthImages[i]
      : pageContentConstrainedImages[i];
  };

  return (
    <Layout
      seo={seo}
      alternateHref={
        editorialContent.AlternateSlug
          ? formatAlternatePath(editorialContent.AlternateSlug, props.type)
          : null
      }
    >
      <Box>
        {headerImage && (
          <BrickImageText
            title=""
            gatsbyImageData={
              headerImage.localFile.childImageSharp.gatsbyImageData
            }
            alternativeText={headerImage.alternativeText}
            mobileImageStyle={{
              maxHeight: "200px",
            }}
            isHeader={true}
          />
        )}
        <Container maxWidth="lg" style={{ padding: 0 }}>
          <Container sx={{ px: 2 }}>
            <Breadcrumb
              title={editorialContent.Title}
              parentTitle={parentTitle}
              parentLink={parentLink}
            />
          </Container>
          <Container sx={{ px: 2, my: 4 }}>
            <Typography variant="h1">{editorialContent.Title}</Typography>
          </Container>
        </Container>
        {pageContent.map((content, i) => (
          <Container
            style={{ padding: 0 }}
            maxWidth={isFullLength(content) ? false : "lg"}
          >
            <Brick
              key={`content__${i}`}
              content={content}
              imageContent={getImageContent(i)}
              seeMoreThumbsContent={pageContentSeeMoreThumbs[i]}
              downloadsContent={pageContentDownloads[i]}
            />
          </Container>
        ))}
        {newsletter && (
          <BrickNewsletter
            title={newsletter.Newsletter.title}
            subtitle={newsletter.Newsletter.subtitle}
            buttonText={newsletter.Newsletter.buttonText}
            backgroundImageData={
              newsletter.backgroundImage[0].localFile.childImageSharp
                .gatsbyImageData
            }
            alternativeText={newsletter.Newsletter.background.alternativeText}
            style={{ padding: 0 }}
          />
        )}
      </Box>
    </Layout>
  );
};

export default EditorialContentComponent;
